<template>
	<div>
		<div class="flex justify-between">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="订单编号">
					<el-input v-model="searchForm.search" placeholder="请输入订单编号" @submit.native.prevent></el-input>
				</el-form-item>
				
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="pageChange(1)">查询</el-button>
				</el-form-item>
			</el-form>
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button @click="toEdit()">创建拼团</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="tableData" v-loading="loading" max-height="9999">
			<el-table-column type="index" width="50"></el-table-column>
			<el-table-column prop="title" label="活动名称" width="200"></el-table-column>
			<el-table-column prop="start_time" label="开始时间" width="200"></el-table-column>
			<el-table-column prop="end_time" label="结束时间" width="200"></el-table-column>
			<el-table-column prop="promotion_status" label="状态" width="100">
				<template slot-scope="{row}">
					<el-tag v-if="row.promotion_status == 0">未开始</el-tag>
					<el-tag v-if="row.promotion_status == 1" type="warning">进行中</el-tag>
					<el-tag v-if="row.promotion_status == 2" type="info">已结束</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="team_count" label="成团订单数" width="100"></el-table-column>
			<el-table-column prop="order_count" label="成团人数" width="100"></el-table-column>
			<el-table-column prop="pay_money_sum" label="销售金额（元）"></el-table-column>
			<el-table-column fixed="right" label="操作" width="300">
				<template slot-scope="{row}">
					<el-button size="mini" type="text" @click="toEdit(row.id)">编辑</el-button>
					<el-button size="mini" type="text">停止</el-button>
					<el-button size="mini" type="text">复制</el-button>
					<el-button size="mini" type="text" @click="$router.push(`/marketingCenter/group/userList`)">用户列表</el-button>
					<el-button size="mini" type="text" @click="$router.push(`/marketingCenter/group/orderList`)">订单列表</el-button>
					<el-button size="mini" type="text">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes"
				:page-size="searchForm.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total"
				@size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				tableData: [],
				searchForm: {
					search: '',
					page: 1,
					page_size: 10
				},
				total: 0
			};
		},
		mounted() {
			this.loadData();
		},
		methods: {
			toEdit(id) {
				if (id) {
					this.$router.push(`/marketingCenter/group/edit/${id}`)
				} else {
					this.$router.push(`/marketingCenter/group/edit`)
				}
			},
			loadData() {
				this.loading = true;
				this.$api.marketing.getGroupList(this.searchForm).then(res => {
					this.loading = false;
					this.tableData = res.data.data;
					this.total = res.data.total;
				});
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.loadData()
			},
		}
	};
</script>
<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		margin-right: 5px;
	}
</style>
